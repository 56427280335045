export default {
  introduction: {
    p1: 'Founded in 2004, Beijing CSP Technology Co., Ltd. is a service provider specializing in China\'s product certification consulting. The company is located in Beijing, providing one-stop service of certification, registration and application for wireless equipment, telecommunication equipment, medical equipment, electronic and electrical products entering China for global customers.',
    p2: 'The company has professional technology and project tracking team, more than 15 years of Chinese certification experience, familiar with wireless equipment type approval, communication equipment access, electronic products CCC and RoHS certification process, thousands of certified experience, is a long-term trust partner of many enterprises and institutions.',
    p3: 'The company keeps up with the changes of laws and regulations, and constantly optimizes and adjusts its services. With years of professional efforts and intensive efforts in China\'s certification industry, we have provided professional and high-quality services to hundreds of manufacturers around the world, with partners in the United States, Europe, Japan, South Korea, Canada, Taiwan and other countries and regions.',
    p4: 'Medical device registration is one of the new businesses launched by the company since 2016. The company effectively shortens the testing process for customers by virtue of its familiarity and expertise in the field of testing, as well as continuous cooperation with testing laboratories.',
    p5: 'The company is based on laws and regulations, professional as a bridge; result oriented, and constantly optimizes the registration process to provide customers services with a variety of choice.'
  },
  header: {
    hello: 'Hello',
    slogan: '15 YEARS CHINA CERTIFICATION CONSULTANT',
    login: 'Login',
    login_tips: {
      title: 'Info',
      content: 'To query the progress, you need to log in the member account first. Please enter your member account / password to log in.'
    },
    login_form: {
      username: 'Username',
      password: 'Password',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      login: 'Member Login',
      logout: 'Logout',
      changePwd: 'Change Password',
      rules: {
        required_username: 'Username is required',
        required_password: 'Password is required',
        required_old_password: 'Old Password is required',
        required_new_password: 'New Password is required'
      }
    },
    banner: {
      item1: '15 Years China Certification Experiences',
      item2: 'results oriented，reliable to the end',
      item3: 'the Age of 5G communication is coming'
    },
    menu: {
      home: 'HOME',
      srrc: 'SRRC',
      nal: 'MII/NAL',
      ccc: 'CCC',
      nmpa: 'NMPA',
      others: 'OTHERS',
      news: 'NEWS',
      news_item: {
        industry_info: 'INDUSTRY INFORMATION',
        company_news: 'COMPANY NEWS'
      },
      service: 'SERVICE',
      service_item: {
        download: 'File Download',
        project: 'Progress Query',
        certificate: 'Certificate Query',
        relation: 'Links'
      },
      contact: 'CONTACT US'
    }
  },
  body: {
    back: 'Back',
    aboutUs: 'About Us',
    aboutUsSlogan: 'CHINA CERTIFICATIONS CONSULTANT',
    title: 'CSP',
    online_consultant: 'Online Consultation',
    weixin: 'WeChat',
    contact: 'Contact Info',
    business_consulting: 'Business Consulting',
    sample_query: 'Sample Query',
    email: 'Email',
    certificate_query: {
      title: 'Certificate Query',
      srrc: {
        label: 'SRRC Certificate Query',
        url: 'https://ythzxfw.miit.gov.cn/index'
      },
      nal: {
        label: 'MII/NAL Certificate Query',
        url: 'https://ythzxfw.miit.gov.cn/index'
      },
      ccc: {
        label: 'CCC Certificate Query',
        url: 'http://cx.cnca.cn/CertECloud/index/index/page'
      },
      nmpa: {
        label: 'NMPA Certificate Query',
        url: 'https://www.nmpa.gov.cn/index.html'
      }
    },
    // cert_query: 'Certificate Query',
    // srrc_query: 'SRRC Query',
    // nal_query: 'NAL Query',
    // ccc_query: 'CCC Query',
    // nmpa_query1: 'NMPA Query1',
    // nmpa_query2: 'NMPA Query2',
    catalog_summary: 'Summary',
    catalog_equipment: 'Equipment catalogue and standards',
    catalog_process: 'Authentication process',
    catalog_materials: 'Application materials',
    catalog_cost_cycle: 'COST&LEADTIME',
    download: 'Download',
    download_note: 'The documents listed above are for reference only and are basic documents. Please consult us for specific projects!',
    news: {
      title: 'Title',
      issueDate: 'Issue Date',
      detail: 'Details'
    },
    project: {
      model: 'Model',
      type: 'Approval Type',
      owner: 'User',
      acceptanceNo: 'Acceptance No.',
      applicant: 'Applicant',
      clientCode: 'Client Code',
      status: 'Status',
      recordNo: 'Certificate No.',
      issueDate: 'Issue Date',
      expireDate: 'Expire Date',
      sampleTrackingNo: 'Sample Tracking No.',
      certificateTrackingNo: 'Certificate Tracking No.',
      paymentStatus: 'Payment Status',
      createdDate: 'Created Date'
    },
    contact_form: {
      rule_required_name: 'Please Enter Name',
      rule_required_contactNo: 'Please Enter Contact No.',
      rule_required_email: 'Please Enter Email',
      rule_required_company: 'Please Enter Company Name',
      rule_required_content: 'Please Enter Message'
    }
  },
  footer: {
    contact_us: 'Online Message',
    name: 'Name',
    mobile: 'Enter Mobile',
    email: 'Enter email',
    company: 'Company Name',
    message: 'Message',
    send: 'SEND',
    address: 'Room 811, The 3rd buliding of Noble Center, Advanced Business Park(ABP) ,No.128, Nansihuan Xilu, Beijing, China',
    relation: {
      title: 'Links',
      link1: {
        label: 'State Radio Regulation of China',
        url: 'http://www.srrc.org.cn'
      },
      link2: {
        label: 'Ministry of Industry and Information Technology of China',
        url: 'http://www.miit.gov.cn'
      },
      link3: {
        label: 'Telecommunication Equipment Certification Center of the Ministry of Industry and Information Technology',
        url: 'http://www.tenaa.com.cn'
      },
      link4: {
        label: 'China Academy of Information and Communication',
        url: 'http://www.caict.ac.cn'
      },
      link5: {
        label: 'China Communications Standardization Association',
        url: 'http://www.ccsa.org.cn'
      },
      link6: {
        label: 'China TTL Laboratory',
        url: 'http://www.chinattl.com'
      },
      link7: {
        label: 'Certification and Accreditation Administration of China',
        url: 'http://www.cnca.gov.cn'
      },
      link8: {
        label: 'China National Accreditation Service for Conformity Assessment',
        url: 'https://www.cnas.org.cn'
      },
      link9: {
        label: 'China Quality Certification Centre',
        url: 'https://www.cqc.com.cn'
      },
      link10: {
        label: 'State Administration for Market Regulation',
        url: 'http://www.samr.gov.cn'
      },
      link11: {
        label: 'National Medical Products Administration',
        url: 'http://www.nmpa.gov.cn'
      },
      link12: {
        label: 'Full Text Publicity of National Standards',
        url: 'http://www.gb688.cn'
      },
      link13: {
        label: 'SDOC system for CCC and RoHS',
        url: 'http://sdoc.cnca.cn/mcsr/login'
      },
      link14: {
        label: 'China Energy Label',
        url: 'https://www.energylabelrecord.com/index.htm'
      }
    }
  }
}
