<template>
  <el-dialog
    title=""
    :visible.sync="isVisible"
    top="50px"
  >
    <el-form
      ref="form"
      :model="dialogForm"
      :rules="dialogRules"
      label-width="130px"
    >
      <el-form-item
        :label="$t('header.login_form.username')"
        prop="username"
      >
        <el-input v-model="dialogForm.username" />
      </el-form-item>
      <el-form-item
        :label="$t('header.login_form.oldPassword')"
        prop="oldPassword"
      >
        <el-input v-model="dialogForm.oldPassword" show-password />
      </el-form-item>
      <el-form-item
        :label="$t('header.login_form.newPassword')"
        prop="newPassword"
      >
        <el-input v-model="dialogForm.newPassword" show-password />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="isVisible = false">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="changePassword">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { changePassword } from '@/api/user'
export default {
  name: 'ChangePwdDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'add'
    }
  },
  data() {
    return {
      loading: false,
      dialogForm: this.initForm(),
      dialogRules: {
        username: [
          { required: true, message: this.$t('header.login_form.rules.required_username'), trigger: 'blur' }
        ],
        oldPassword: [
          { required: true, message: this.$t('header.login_form.rules.required_old_password'), trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: this.$t('header.login_form.rules.required_new_password'), trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    isVisible: {
      get() {
        return this.visible
      },
      set() {
        this.close()
        this.reset()
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    reset() {
      this.$refs.form.clearValidate()
      this.$refs.form.resetFields()
      this.dialogForm = this.initForm()
    },
    initForm() {
      return {
        username: '',
        oldPassword: '',
        newPassword: ''
      }
    },
    changePassword() {
      this.$refs.form.validate((valid) => {
        this.loading = true
        if (valid) {
          changePassword(this.dialogForm).then(resp => {
            this.loading = false
            if (resp.status) {
              this.$message({
                message: '密码修改成功！',
                type: 'success'
              })
            } else {
              this.$message({
                message: resp.message,
                type: 'error'
              })
            }
          }).catch(err => {
            console.log(err)
            this.loading = false
          })
        } else {
          this.loading = false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
