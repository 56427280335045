import Layout from '@/layout'

const cspRouter = {
  path: '/',
  component: Layout,
  redirect: '/home',
  children: [
    {
      path: '/home',
      name: 'Home',
      component: () => import('@/views/Home')
    },
    {
      path: '/srrc',
      name: 'Srrc',
      component: () => import('@/views/srrc/Index')
    },
    {
      path: '/nal',
      name: 'Nal',
      component: () => import('@/views/nal/Index')
    },
    {
      path: '/ccc',
      name: 'CCC',
      component: () => import('@/views/ccc/Index')
    },
    {
      path: '/nmpa',
      name: 'Nmpa',
      component: () => import('@/views/nmpa/Index')
    },
    {
      path: '/others',
      name: 'Others',
      component: () => import('@/views/others/Index')
    },
    {
      path: '/news',
      redirect: '/news/industry-info',
      component: () => import('@/views/news/Index'),
      children: [
        {
          path: '/news/industry-info',
          name: 'IndustryInfo',
          component: () => import('@/views/news/IndustryInfo')
        },
        {
          path: '/news/company-news',
          name: 'CompanyNews',
          component: () => import('@/views/news/CompanyNews')
        },
        {
          path: '/news/news-list',
          name: 'NewsList',
          component: () => import('@/views/news/NewsList')
        },
        {
          path: '/news/news-detail',
          name: 'NewsDetail',
          component: () => import('@/views/news/NewsDetail')
        }
      ]
    },
    {
      path: '/service',
      redirect: '/service/download',
      component: () => import('@/views/service/Index'),
      children: [
        {
          path: '/service/download',
          name: 'Download',
          component: () => import('@/views/service/Download')
        },
        {
          path: '/service/project',
          name: 'Project',
          component: () => import('@/views/service/Project')
        },
        {
          path: '/service/certification',
          name: 'Certification',
          component: () => import('@/views/service/Certification')
        },
        {
          path: '/service/relation',
          name: 'Relation',
          component: () => import('@/views/service/Relation')
        },
        {
          path: '/service/contact',
          name: 'Contact',
          component: () => import('@/views/service/Contact')
        }
      ]
    }
  ]
}

export default cspRouter
