import { getLocale, setLocale, getLanguage } from '@/utils/i18n'

const state = {
  locale: getLocale(),
  lang: getLanguage()
}
const mutations = {
  SET_LOCALE: (state, locale) => {
    setLocale(locale)
    state.locale = locale
    state.lang = getLanguage()
  }
}
const actions = {
  setLocale({ commit }, locale) {
    commit('SET_LOCALE', locale)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
