import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/oauth/token?username=' + data.username + '&password=' + data.password + '&grant_type=password&client_id=bamboo_csptech&client_secret=123456',
    method: 'post',
    data
  })
  // return request({
  //   url: '/user/login',
  //   method: 'post',
  //   data
  // })
}
export function changePassword(data) {
  return request({
    url: '/user/change-password',
    method: 'post',
    data
  })
}
export function resetPassword(data) {
  return request({
    url: '/user/reset-password',
    method: 'post',
    data: data
  })
}
export function getInfo(token) {
  return request({
    url: '/user/info',
    method: 'get',
    params: { token }
  })
}

export function logout() {
  return request({
    url: '/user/logout',
    method: 'post'
  })
}
export function duplicateCheck(type, value) {
  return request({
    url: '/user/duplicateCheck',
    method: 'get',
    params: { type: type, content: value }
  })
}
export function loadUsers() {
  return request({
    url: '/user/list',
    method: 'get',
    params: {}
  })
}
export function searchUser(keyword) {
  return request({
    url: '/user/search',
    method: 'get',
    params: { keyword: keyword }
  })
}
export function saveOrUpdateUser(data) {
  return request({
    url: '/user/save',
    method: 'post',
    data: data
  })
}
export function deleteUser(id) {
  return request({
    url: `/user/${id}`,
    method: 'delete'
  })
}
export function loadRoles() {
  return request({
    url: '/user/roles',
    method: 'get'
  })
}
export function saveOrUpdateRole(data) {
  return request({
    url: '/user/role',
    method: 'post',
    data: data
  })
}
export function deleteRole(id) {
  return request({
    url: `/user/role/${id}`,
    method: 'delete'
  })
}
export function loadPermissions(roleId) {
  const params = (roleId && roleId !== 0) ? { roleId: roleId } : {}
  return request({
    url: '/user/permissions',
    method: 'get',
    params: params
  })
}
export function assignedPermissions(roleId) {
  return request({
    url: '/user/assigned-permissions',
    method: 'get',
    params: { roleId: roleId }
  })
}
