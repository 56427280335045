
export function getLocale() {
  return localStorage.getItem('locale') || 'en'
}
export function setLocale(locale) {
  localStorage.setItem('locale', locale)
}
export function getLanguage() {
  const locale = getLocale()
  if (locale === 'en') {
    return 'English'
  } else {
    return '中文'
  }
}
