export default {
  introduction: {
    p1: '北京科思普科技有限公司创立于2004年，是一家专业从事中国产品认证咨询的服务提供商。公司位于北京，为全球客户提供进入中国的无线设备、电信设备、医疗器械、电子电器产品的认证及注册申报一站式服务。',
    p2: '公司拥有专业技术及项目跟踪团队，超过15年以上的中国认证经验，熟悉无线设备型号核准、通信设备入网、电子产品CCC和RoHS等认证流程，有数千张获证经验，是众多企业及机构长期信任的合作伙伴。',
    p3: '公司紧跟法规的变化，不断优化调整自身服务。凭借多年来在中国认证行业的专业努力和深耕细作，已为全球数百家制造商提供了专业、优质的服务，合作伙伴遍布美、欧、日、韩、加、台湾等国家和地区。',
    p4: '医疗器械注册是公司从2016年后开展的新业务之一。公司凭借在测试领域的熟悉和专业，以及与测试实验室的持续合作，为客户有效缩短测试流程。公司以法规为基础、专业为桥梁、结果为导向，在注册流程不断进行优化，为客户提供多种选择服务。',
    p5: '专业精益求精，服务永无止境，我们仍将致力于通过专业服务为客户建立便捷高效的上市通道，协助优质产品快速进入市场，惠及中国用户！'
  },
  header: {
    hello: '你好',
    slogan: '15年中国认证专业服务商',
    login: '会员登录',
    login_tips: {
      title: '友情提示',
      content: '查询进度需要先登录会员账号，请输入您的会员账号/密码前往登录。'
    },
    login_form: {
      username: '用户名',
      password: '密码',
      oldPassword: '旧密码',
      newPassword: '新密码',
      login: '登录',
      logout: '退出登录',
      changePwd: '修改密码',
      rules: {
        required_username: '请输入用户名',
        required_password: '请输入密码',
        required_old_password: '请输入旧密码',
        required_new_password: '请输入新密码'
      }
    },
    banner: {
      item1: '15年中国认证经验，助力获取市场先机',
      item2: '法规为基础，专业为桥梁，结果为导向',
      item3: '5G时代，认证先锋'
    },
    menu: {
      home: '首页',
      srrc: '型号核准',
      nal: '进网许可',
      ccc: 'CCC',
      nmpa: 'NMPA',
      others: '其他认证',
      news: '资讯动态',
      news_item: {
        industry_info: '行业资讯',
        company_news: '公司新闻'
      },
      service: '服务支持',
      service_item: {
        download: '文件下载',
        project: '进度查询',
        certificate: '证书查询',
        relation: '友情链接'
      },
      contact: '联系我们'
    }
  },
  body: {
    back: '返回',
    aboutUs: '关于我们',
    aboutUsSlogan: '中国认证专业服务商',
    title: '北京科思普科技有限公司',
    online_consultant: '在线咨询',
    weixin: '微信',
    contact: '联系电话/邮箱',
    business_consulting: '业务咨询',
    sample_query: '样品查询',
    email: '邮箱',
    certificate_query: {
      title: '证书查询',
      srrc: {
        label: '型号核准证书查询',
        url: 'https://ythzxfw.miit.gov.cn/index'
      },
      nal: {
        label: '进网许可证书查询',
        url: 'https://ythzxfw.miit.gov.cn/index'
      },
      ccc: {
        label: 'CCC证书查询',
        url: 'http://cx.cnca.cn/CertECloud/index/index/page'
      },
      nmpa: {
        label: 'NMPA证书查询',
        url: 'https://www.nmpa.gov.cn/index.html'
      }
    },
    // cert_query: '证书查询',
    // srrc_query: '型号核准查询',
    // nal_query: '进网许可证查询',
    // ccc_query: 'CCC认证查询',
    // nmpa_query1: 'NMPA证书查询1',
    // nmpa_query2: 'NMPA证书查询2',
    catalog_summary: '概述',
    catalog_equipment: '设备目录及标准',
    catalog_process: '认证流程',
    catalog_materials: '申请材料',
    catalog_cost_cycle: '费用周期',
    download: '下载',
    download_note: '上列各项文件仅供参考，为基本文件，具体项目请咨询我们!',
    news: {
      title: '新闻标题',
      issueDate: '发布时间',
      detail: '查看详情'
    },
    project: {
      model: '产品型号',
      type: '项目类型',
      owner: '项目所属者',
      acceptanceNo: '受理编号',
      applicant: '申请人',
      clientCode: '客户代码',
      status: '阶段状态',
      recordNo: '证书号',
      issueDate: '发证日期',
      expireDate: '到期日期',
      sampleTrackingNo: '退样单号',
      certificateTrackingNo: '寄证单号',
      paymentStatus: '支付状态',
      createdDate: '创建日期'
    },
    contact_form: {
      rule_required_name: '请输入名字',
      rule_required_contactNo: '请输入联系方式',
      rule_required_email: '请输入Email',
      rule_required_company: '请输入公司名称',
      rule_required_content: '请输入内容'
    }
  },
  footer: {
    contact_us: '在线留言',
    name: '姓名',
    mobile: '手机号码',
    email: 'E-Mail',
    company: '公司名称',
    message: '内容',
    send: '发送',
    address: '北京市南四环西路128号总部基地北京诺德中心3号楼811室',
    relation: {
      title: '友情链接',
      link1: {
        label: '中国无线电管理局',
        url: 'http://www.srrc.org.cn'
      },
      link2: {
        label: '中国工业和信息化部',
        url: 'http://www.miit.gov.cn'
      },
      link3: {
        label: '工业和信息化部电信设备认证中心',
        url: 'http://www.tenaa.com.cn'
      },
      link4: {
        label: '中国信息通信研究院',
        url: 'http://www.caict.ac.cn'
      },
      link5: {
        label: '中国通信标准化协会',
        url: 'http://www.ccsa.org.cn'
      },
      link6: {
        label: '中国泰尔实验室',
        url: 'http://www.chinattl.com'
      },
      link7: {
        label: '中国国家认证认可监督管理委员会',
        url: 'http://www.cnca.gov.cn'
      },
      link8: {
        label: '中国合格评定国家认可委员会',
        url: 'https://www.cnas.org.cn'
      },
      link9: {
        label: '中国质量认证中心',
        url: 'https://www.cqc.com.cn'
      },
      link10: {
        label: '国家市场监督管理总局',
        url: 'http://www.samr.gov.cn'
      },
      link11: {
        label: '国家药品监督管理局',
        url: 'http://www.nmpa.gov.cn'
      },
      link12: {
        label: '国家标准全文公开',
        url: 'http://www.gb688.cn'
      },
      link13: {
        label: 'CCC和RoHS自我声明符合性信息报送系统',
        url: 'http://sdoc.cnca.cn/mcsr/login'
      },
      link14: {
        label: '中国能源标识网',
        url: 'https://www.energylabelrecord.com/index.htm'
      }
    }
  }
}
