// import Cookies from 'js-cookie'

const tokenKey = 'Bamboo-CSP-Token'

export function getAccessToken() {
  const token = getToken()
  return token ? token.access_token : ''
}
export function getRefreshToken() {
  const token = getToken()
  return token ? token.refresh_token : ''
}
function getToken() {
  const token = localStorage.getItem(tokenKey)
  return JSON.parse(token)
}
export function setToken(token) {
  localStorage.setItem(tokenKey, JSON.stringify(token))
}
export function removeToken() {
  localStorage.removeItem(tokenKey)
}
