import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import moment from 'moment'

import Element from 'element-ui'
import './styles/element-variables.scss'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import '@/styles/index.scss' // global css
import './assets/style/theme/index.css'

import VueI18n from 'vue-i18n'
import enLocale from './i18n/en'
import zhLocale from './i18n/zh-CN'

// import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log

Vue.use(VueI18n)
const messages = {
  'en': enLocale,
  'zh-CN': zhLocale
}
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en',
  messages
})

// import 'owl.carousel/dist/owl.carousel.min.js'
// import 'owl.carousel/dist/assets/owl.carousel.min.css'

// import 'animate.css'
//
// import'font-awesome/css/font-awesome.min.css'
//
// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min'

// import '@/assets/css/style.css'

Vue.config.productionTip = false

Vue.use(Element)

Vue.filter('datetimeFilter', (value, withTime = false) => {
  if (value) {
    const format = withTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'
    return moment(value).format(format)
  }
})
Vue.filter('dateYear', (value, locale = 'en') => {
  if (value) {
    return moment(value).format('YYYY')
  }
})
Vue.filter('dateMonth', (value, locale = 'en') => {
  if (value) {
    locale = locale.toLowerCase()
    moment().locale(locale)
    return moment(value).locale(locale).format('MMM')
  }
})
Vue.filter('dateDay', (value, locale = 'en') => {
  if (value) {
    return moment(value).format('DD')
  }
})

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
// new Vue({
//   el: '#app',
//   router,
//   store,
//   render: h => h(App)
// })
